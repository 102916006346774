/*******************************************************************************
 * Themes
 ******************************************************************************/

html {
  --global-bg-color: #fdf6e3;
  --global-bg-rgb: 253,246,227;
  --global-text-color: #002b36;
  --global-theme-color: #{$purple-color};
  --global-hover-color: #{$light-purple-color};
  --global-footer-bg-color: #{$grey-color-dark};
  --global-footer-text-color: #{$grey-color-light};
  --global-footer-link-color: #{$white-color};
  --global-icon-color: #{$grey-color-dark};
  --global-noticebox-color: #{$orange-color};

  .fa-sun {
    display : none;
  }
  .fa-moon {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }

  img {
    background-color: #{$white-color};
  }
}

html[data-theme='dark'] {
  /* solarized dark base03 color = #002b36 */
  --global-bg-color: #002b36;
  --global-bg-rgb: 0,43,54;
  --global-text-color: #fdf6e3;
  --global-theme-color: #{$cyan-color};
  --global-hover-color: #{$light-cyan-color};
  --global-footer-bg-color: #{$grey-color-light};
  --global-footer-text-color: #{$grey-color-dark};
  --global-footer-link-color: #{$black-color};
  --global-icon-color: navajowhite;
  --global-noticebox-color: #{$dark-orange-color};

  .fa-sun {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
  .fa-moon {
    display : none;
  }

  figcaption,.figcaption {
    color: var(--global-text-color);
  }
  img {
    background-color: #{$grey-color-light};
  }

  d-article, d-byline a, d-article d-byline a {
    color: var(--global-text-color);
  }

  d-byline h3, d-appendix h3 {
    color: var(--global-text-color);
  }

  d-appendix {
    color: var(--global-text-color);
  }


}
